import './App.css';
import { Carousel } from 'react-carousel-minimal';

function App() {
  const data = [

    {
      image: '/img/tir_slider_8.png',
      caption: "АНО ДПО «ШКОЛА БЕЗОПАСНОСТИ»"
    },
    {
      image: '/img/tir_slider_10.png',
      caption: ""
    },
    {
      image: "/img/tir_slider_1.png",
      caption: ""
    },
    {
      image: "/img/tir_slider_9.png",
      caption: ""
    },
    {
      image: "/img/tir_slider_2.png",
      caption: ""
    },
    {
      image: "/img/tir_slider_3.png",
      caption: ""
    },
    {
      image: "/img/tir_slider_4.png",
      caption: ""
    },
  ];

  const captionStyle = {
    fontSize: '3em',
    fontWeight: 'bold',
    color: '#ffffff',
    paddingBottom: '10px',
    lineHeight: '60px'
  }
  const slideNumberStyle = {
    fontSize: '20px',
    fontWeight: 'bold',
  }
  return (
    <div className="App">
      <div style={{ textAlign: "center" }}>
        <div style={{
          padding: "0px"
        }}>
          <Carousel
            data={data}
            time={2000}
            width="100vw"
            height="500px"
            captionStyle={captionStyle}
            radius="0px"
            slideNumber={true}
            slideNumberStyle={slideNumberStyle}
            captionPosition="bottom"
            automatic={true}
            dots={true}
            pauseIconColor="white"
            pauseIconSize="60px"
            slideBackgroundColor="darkgrey"
            slideImageFit="cover"
            thumbnails={false}
            thumbnailWidth="100px"
            style={{
              textAlign: "center",
              maxWidth: "100vw",
              maxHeight: "500px",
              margin: "0",
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default App;



